import React from 'react';
import "./Loader.scss";

function Loader({ insideModal, fullScreen, insideModalInventory }) {
  let loaderClass = "go-loader";

  if (insideModal) {
    loaderClass += " go-loader--inside-modal";
  }

  if (insideModalInventory) {
    loaderClass += " go-loader--inside-modal-inventory";
  }

  if (fullScreen) {
    loaderClass += " go-loader--fullscreen";
  }

  return (
    <div className={loaderClass}></div>
  );
}

export default Loader;
