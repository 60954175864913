// components/Icons.js
import React from 'react';

export const InventariumIsotype = () => (
    <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2 go-brand'>
        <path className="path-stroke-primary" d="M14.1667 1.66667C16.6667 1.66667 18.3334 3.33334 18.3334 5.83334V7.50001" stroke="var(--text-color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-secondary" d="M1.66675 7.50001V5.83334C1.66675 3.33334 3.33341 1.66667 5.83341 1.66667H10.6418" stroke="var(--theme-color)" strokeOpacity="0.988235" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-secondary" d="M18.3333 12.5V14.1667C18.3333 16.6667 16.6667 18.3333 14.1667 18.3333H9.55835" stroke="var(--theme-color)" strokeOpacity="0.988235" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-primary" d="M1.66675 12.5V14.1667C1.66675 16.6667 3.33341 18.3333 5.83341 18.3333" stroke="var(--text-color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-primary" d="M5.58325 7.71667L9.99992 10.275L14.3833 7.73336" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-primary" d="M10 14.8084V10.2667" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-primary" d="M8.96661 5.24166L6.29994 6.72502C5.69994 7.05835 5.19995 7.90001 5.19995 8.59168V11.4167C5.19995 12.1084 5.69161 12.95 6.29994 13.2833L8.96661 14.7667C9.53328 15.0833 10.4666 15.0833 11.0416 14.7667L13.7083 13.2833C14.3083 12.95 14.8083 12.1084 14.8083 11.4167V8.59168C14.8083 7.90001 14.3166 7.05835 13.7083 6.72502L11.0416 5.24166C10.4666 4.91666 9.53328 4.91666 8.96661 5.24166Z" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const InventariumIsotypeWhite = () => (
    <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2 go-brand'>
        <path className="path-stroke-primary" d="M14.1667 1.66667C16.6667 1.66667 18.3334 3.33334 18.3334 5.83334V7.50001" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-secondary" d="M1.66675 7.50001V5.83334C1.66675 3.33334 3.33341 1.66667 5.83341 1.66667H10.6418" stroke="var(--theme-color)" strokeOpacity="0.988235" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-secondary" d="M18.3333 12.5V14.1667C18.3333 16.6667 16.6667 18.3333 14.1667 18.3333H9.55835" stroke="var(--theme-color)" strokeOpacity="0.988235" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-primary" d="M1.66675 12.5V14.1667C1.66675 16.6667 3.33341 18.3333 5.83341 18.3333" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-primary" d="M5.58325 7.71667L9.99992 10.275L14.3833 7.73336" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-primary" d="M10 14.8084V10.2667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="path-stroke-primary" d="M8.96661 5.24166L6.29994 6.72502C5.69994 7.05835 5.19995 7.90001 5.19995 8.59168V11.4167C5.19995 12.1084 5.69161 12.95 6.29994 13.2833L8.96661 14.7667C9.53328 15.0833 10.4666 15.0833 11.0416 14.7667L13.7083 13.2833C14.3083 12.95 14.8083 12.1084 14.8083 11.4167V8.59168C14.8083 7.90001 14.3166 7.05835 13.7083 6.72502L11.0416 5.24166C10.4666 4.91666 9.53328 4.91666 8.96661 5.24166Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const InventariumInformation = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 9V8.81337C8.5 8.20892 8.87955 7.88891 9.25907 7.63113C9.62956 7.38224 10 7.06225 10 6.47559C10 5.65781 9.33134 5 8.5 5C7.66866 5 7 5.65781 7 6.47559" stroke="var(--text-color)" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.49548 11.5H8.50448" stroke="var(--text-color)" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.49996 15.5834C12.412 15.5834 15.5833 12.412 15.5833 8.50002C15.5833 4.588 12.412 1.41669 8.49996 1.41669C4.58794 1.41669 1.41663 4.588 1.41663 8.50002C1.41663 12.412 4.58794 15.5834 8.49996 15.5834Z" stroke="var(--text-color)" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const InventariumAI = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 3H9C4 3 2 5 2 10V16C2 21 4 23 9 23H15C20 23 22 21 22 16V11" stroke="var(--theme-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.62 12.62L11.5 11L12.38 12.62L14 13.5L12.38 14.38L11.5 16L10.62 14.38L9 13.5L10.62 12.62Z" fill="var(--theme-color)" stroke="var(--theme-color)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.564 5.56399L17.5 2L19.436 5.56399L23 7.5L19.436 9.43601L17.5 13L15.564 9.43601L12 7.5L15.564 5.56399Z" fill="var(--theme-color)" stroke="var(--theme-color)" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.972 1.972L21.5 1L22.028 1.972L23 2.5L22.028 3.028L21.5 4L20.972 3.028L20 2.5L20.972 1.972Z" fill="var(--theme-color)" stroke="var(--theme-color)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const InventariumIdea = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0001 7.88989L10.9301 9.74989C10.6901 10.1599 10.8901 10.4999 11.3601 10.4999H12.6301C13.1101 10.4999 13.3001 10.8399 13.0601 11.2499L12.0001 13.1099" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.30011 18.0399V16.8799C6.00011 15.4899 4.11011 12.7799 4.11011 9.89993C4.11011 4.94993 8.66011 1.06993 13.8001 2.18993C16.0601 2.68993 18.0401 4.18993 19.0701 6.25993C21.1601 10.4599 18.9601 14.9199 15.7301 16.8699V18.0299C15.7301 18.3199 15.8401 18.9899 14.7701 18.9899H9.26011C8.16011 18.9999 8.30011 18.5699 8.30011 18.0399Z" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.5 22C10.79 21.35 13.21 21.35 15.5 22" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const InventariumPen = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001Z" stroke="var(--text-color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.91 4.1499C15.58 6.5399 17.45 8.4099 19.85 9.0899" stroke="var(--text-color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const InventariumPasswordCheck = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.02 19.5H7.5C6.88 19.5 6.33 19.48 5.84 19.41C3.21 19.12 2.5 17.88 2.5 14.5V9.5C2.5 6.12 3.21 4.88 5.84 4.59C6.33 4.52 6.88 4.5 7.5 4.5H10.96" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.02 4.5H16.5C17.12 4.5 17.67 4.52 18.16 4.59C20.79 4.88 21.5 6.12 21.5 9.5V14.5C21.5 17.88 20.79 19.12 18.16 19.41C17.67 19.48 17.12 19.5 16.5 19.5H15.02" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 2V22" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const InventariumAdd = () => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 11.5C22 12.34 21.895 13.1537 21.685 13.9412C21.4488 14.9912 21.0288 16.015 20.4775 16.9075C18.6663 19.9525 15.3325 22 11.5 22C8.79625 22 6.35499 20.9762 4.51749 19.2962C3.72999 18.6137 3.04746 17.8 2.52246 16.9075C1.55121 15.3325 1 13.4688 1 11.5C1 8.665 2.12876 6.06631 3.96626 4.17631C5.88251 2.20756 8.56 1 11.5 1C14.5975 1 17.4063 2.33879 19.2963 4.49129C20.9763 6.35504 22 8.8225 22 11.5Z" 
              stroke="var(--text-color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.4084 11.4453H7.58594" 
              stroke="var(--text-color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.5 7.61328V15.462" 
              stroke="var(--text-color)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);


export const InventariumTrash = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 4.98356C14.725 4.70856 11.9333 4.56689 9.15 4.56689C7.5 4.56689 5.85 4.65023 4.2 4.81689L2.5 4.98356" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.08301 4.1415L7.26634 3.04984C7.39967 2.25817 7.49967 1.6665 8.90801 1.6665H11.0913C12.4997 1.6665 12.608 2.2915 12.733 3.05817L12.9163 4.1415" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.7087 7.6167L15.167 16.0084C15.0753 17.3167 15.0003 18.3334 12.6753 18.3334H7.32533C5.00033 18.3334 4.92533 17.3167 4.83366 16.0084L4.29199 7.6167" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.6084 13.75H11.3834" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.91699 10.4165H12.0837" stroke="var(--text-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);


// Add more icons as needed
