import React, { lazy, Suspense } from "react";

// Lazy loading components
// const LoginScreen = lazy(() => import("../../screens/GoMatch/LoginScreen"));
// const HomeScreen = lazy(() => import("../../screens/GoMatch/HomeScreen"));
const FeedScreen = lazy(() => import("../../screens/GoMatch/FeedScreen"));
const CourtScreen = lazy(() => import("../../screens/GoMatch/CourtScreen"));
//const RegisterScreen = lazy(() => import("../../screens/GoMatch/RegisterScreen"));
const BuildingMatchScreen = lazy(() => import("../../screens/GoMatch/BuildingMatchScreen"));
const ProfileScreen = lazy(() => import("../../screens/GoMatch/ProfileScreen"));
const BookingsScreen = lazy(() => import("../../screens/GoMatch/BookingScreen"));
const PaymentScreen = lazy(() => import("../../screens/GoMatch/PaymentScreen"));
const AdminLoginScreen = lazy(() => import("../../screens/Admin/AdminLoginScreen"));
const AdminDashboardScreen = lazy(() => import("../../screens/Admin/AdminDashboardScreen"));
const AdminUserList = lazy(() => import("../../screens/Admin/AdminUserListScreen"));
const AdminConfigurationScreen = lazy(() => import("../../screens/Admin/AdminConfigurationScreen"));
const AdminEditClubScreen = lazy(() => import("../../screens/Admin/AdminEditClubScreen"));
const AdminPaymentScreen = lazy(() => import("../../screens/Admin/AdminPaymentScreen"));
const AdminStatisticsScreen = lazy(() => import("../../screens/Admin/AdminStatisticsScreen"));
const GoMarketHomeScreen = lazy(() => import("../../screens/GoMarket/GoMarketHomeScreen"));
const GoMarketInventoryScreen = lazy(() => import("../../screens/GoMarket/GoMarketInventoryScreen"));
const GoMarketProductsScreen = lazy(() => import("../../screens/GoMarket/GoMarketProductsScreen"));
const GoMarketItemManagerScreen = lazy(() => import("../../screens/GoMarket/GoMarketItemManagerScreen"));
const GoMarketOrdersScreen = lazy(() => import("../../screens/GoMarket/GoMarketOrdersScreen"));
const GoMarketOrdersPendingScreen = lazy(() => import("../../screens/GoMarket/GoMarketOrdersPendingScreen"));
const GoMarketCashRegisterRecordsScreen = lazy(() => import("../../screens/GoMarket/GoMarketCashRegisterRecordsScreen"));
const AdminSchedulesNewScreen = lazy(() => import("../../screens/Admin/AdminSchedulesNewScreen"));
const AdminSchedulesScreenv2 = lazy(() => import("../../screens/Admin/AdminSchedulesScreenv2"));
const AdminScheduleSettingsScreen = lazy(() => import("../../screens/Admin/AdminScheduleSettingsScreen"));
const AdminReportingScreen = lazy(() => import("../../screens/Admin/AdminReportingScreen"));
const GoBalancesBalancesScreen = lazy(() => import("../../screens/GoBalances/GoBalancesBalancesScreen"));
const GoBalancesBalanceScreen = lazy(() => import("../../screens/GoBalances/GoBalancesBalanceScreen"));
const GoAIChat = lazy(() => import("../../screens/GoAI/GoAIChat"));

// Define the privateAdminRoutes array with lazy-loaded components
export const privateAdminRoutes = [
  {
    path: "/match/:slug",
    element: (
      <Suspense  >
        <BuildingMatchScreen />
      </Suspense>
    ),
  },
  {
    path: "/profile",
    element: (
      <Suspense >
        <ProfileScreen />
      </Suspense>
    ),
  },
  {
    path: "/feed",
    element: (
      <Suspense >
        <FeedScreen />
      </Suspense>
    ),
  },
  {
    path: "/court",
    element: (
      <Suspense >
        <CourtScreen />
      </Suspense>
    ),
  },
  {
    path: "/reservas",
    element: (
      <Suspense >
        <BookingsScreen />
      </Suspense>
    ),
  },
  {
    path: "/payment/:slug",
    element: (
      <Suspense >
        <PaymentScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin_login",
    element: (
      <Suspense >
        <AdminLoginScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin_dashboard",
    element: (
      <Suspense >
        <AdminDashboardScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/lista_usuarios",
    element: (
      <Suspense >
        <AdminUserList />
      </Suspense>
    ),
  },
  {
    path: "/admin/configuracion",
    element: (
      <Suspense >
        <AdminConfigurationScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/configuracion/editar_club",
    element: (
      <Suspense >
        <AdminEditClubScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/reservas",
    element: (
      <Suspense >
        <AdminSchedulesScreenv2 />
      </Suspense>
    ),
  },
  {
    path: "/admin/reservas/:id",
    element: (
      <Suspense >
        <AdminScheduleSettingsScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/reservas/new",
    element: (
      <Suspense >
        <AdminSchedulesNewScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/estadisticas",
    element: (
      <Suspense >
        <AdminStatisticsScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/pagos",
    element: (
      <Suspense >
        <AdminPaymentScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/punto-de-venta",
    element: (
      <Suspense >
        <GoMarketHomeScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/inventario",
    element: (
      <Suspense >
        <GoMarketInventoryScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/inventario/productos",
    element: (
      <Suspense >
        <GoMarketProductsScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/inventario/nuevo-producto",
    element: (
      <Suspense >
        <GoMarketItemManagerScreen isNew={true} />
      </Suspense>
    ),
  },
  {
    path: "/admin/inventario/:id_item",
    element: (
      <Suspense >
        <GoMarketInventoryScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/ventas",
    element: (
      <Suspense >
        <GoMarketOrdersScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/pendientes",
    element: (
      <Suspense >
        <GoMarketOrdersPendingScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/pendientes/:id_order",
    element: (
      <Suspense >
        <GoMarketOrdersPendingScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/ventas/:id_order",
    element: (
      <Suspense >
        <GoMarketOrdersScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/historial-cajas",
    element: (
      <Suspense >
        <GoMarketCashRegisterRecordsScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/historial-cajas/:id_record",
    element: (
      <Suspense >
        <GoMarketCashRegisterRecordsScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/reportes",
    element: (
      <Suspense >
        <AdminReportingScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/balances",
    element: (
      <Suspense>
        <GoBalancesBalancesScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/balances/:id_record",
    element: (
      <Suspense>
        <GoBalancesBalanceScreen />
      </Suspense>
    ),
  },
  {
    path: "/admin/IA",
    element: (
      <Suspense>
        <GoAIChat />
      </Suspense>
    ),
  }
];
